@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Nunito:wght@200;1000&display=swap');


.grid-child {
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-direction: row; 
  flex-wrap: wrap; 
  text-align: center; 
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.grid-child-email {
  display: flex;
  justify-content: center;
  color: red;
  padding: 10px;
  flex-direction: row; 
  flex-wrap: wrap; 
  text-align: center; 
  justify-content: center;


}

.content-container {

  width: 100%;
  aspect-ratio: 1/1;
  max-width: 400px;
  height: 400 px;
  padding: 20px;
  border: 5px rgb(91, 109, 244) solid;
  font-size: 16px;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  border-radius: 30px;
  font-size: 25px;
  background-color: rgb(207, 216, 242);
  animation: mymove 5s infinite;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.icons-container {
  display: flex;
  width: 100%;
  aspect-ratio: 1/1;
  flex-wrap: wrap; 
  max-width: 400px;
  align-items: center; 
  padding: 20px;
  flex-direction: row;
  border: 5px rgb(91, 109, 244) solid;
  font-size: 16px;
  color: black;
  border-radius: 30px;
  justify-content: center;
  background-color: rgb(207, 216, 242);
  animation: mymove 5s infinite;
}

.icon-wrap {
  width: 100px;
  padding: 30px;
}
.advertisement-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.advertisement-content-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  width: 100%;
  height:200px;
  border-radius: 30px;
  align-items: center;
  padding: 20px;
  margin: 10px;
  flex-direction: column;
  border: 5px rgb(91, 109, 244) solid;
  text-align: center;
  color: #0721e7; 
  max-width:918px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 33px;
  background-color:rgb(207, 216, 242);
  animation: mymove 5s infinite;
}

@media (min-width: 800px) {
  .advertisement-content-container {
    font-size: 40px;
  }
}

@keyframes mymove {
  50% {
    box-shadow: 10px 20px 30px blue;
  }
}

.grid-container {
display: flex;
justify-content: center;
flex-direction: column;
margin-top: 40px;
margin-bottom: 40px;
gap: 40px;
}

@media (min-width: 800px) {
  .grid-container {
    flex-direction: row;
  }
}

a:active {
  color:blueviolet;
}

a:visited {
  color: blueviolet;
}