
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Nunito:wght@200;400;600;1000&family=Orbit&display=swap');




.survey-wrapper {
    color: blue;
  justify-content:center;
  height: 100%;
  align-items: center;
  flex-direction:column;
  display:flex;
  padding: 50px;
  font-family: 'Nunito', sans-serif;
}

.font-link {
padding: 15px;
padding-bottom:15px;
text-align: center;
display:flex;
justify-content:center;
align-items: center;
font-size: 20px;
font-family: 'Nunito', sans-serif;
}


.form-box {
  padding: 25px; 
  margin-top: 6px;
  margin-bottom: 40px;
  border-radius: 10px; 
  border: 2px solid rgb(63, 118, 195);
  animation: mymove 5s infinite;

}

@keyframes mymove {
  50% {box-shadow: 3px 6px 9px blue;}

}

.form {
  display: flex; 
  justify-content: center; 
  align-items: center;
  flex-direction: column;
}
.submit {
  background: rgb(115, 160, 233);
  padding:15px;
  border-radius:10px;
  display: flex;
  justify-content: center;
  align-items:center; 
  color:black;
  border-color: black;

}
a:link{
  text-decoration:none;
}

.check-box-group {
  display: inline-block;
  text-align: center;
}
.check-box-group label{
 display:inline-block;
 padding: 10px;
}

.circle-label {
  display: block;
}

.instructions {
  max-width: 600px;
  padding: 15px;
padding-bottom:15px;
text-align: center;
display:flex;
justify-content:center;
align-items: center;
font-size: 20px;
font-family: 'Nunito', sans-serif;
color:black;
font-weight: 600;
border: 2px solid black;
}