html,
body,
#root {
  min-height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: blue;
}

.App-link {
  color: rgb(255, 0, 183);
}

@keyframes App-logo-spin {
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

body, html {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}